// src/components/Main.js
import React from 'react';
import Section from './Section';
import './Main.css';

const Main = () => {
  const sections = [
    {
      title: "1. Desarrollo de Software a Medida",
      description: "Creación de aplicaciones personalizadas adaptadas a los requisitos específicos del cliente.",
      listTitle: "Aplicaciones",
      items: [
        "Sistemas de gestión empresarial",
        "Herramientas internas",
        "Soluciones específicas para industrias"
      ]
    },
    {
      title: "2. Desarrollo de Aplicaciones Web",
      description: "Diseño y desarrollo de sitios web y aplicaciones web dinámicas.",
      listTitle: "Tecnologías comunes",
      items: [
        "HTML, CSS, JavaScript",
        "Backend con Php, Laravel"
      ]
    },
    {
      title: "3. Desarrollo de Aplicaciones Móviles",
      description: "Creación de aplicaciones para dispositivos móviles en plataformas iOS y Android.",
      listTitle: "Tecnologías comunes",
      items: [
        "Flutter"
      ]
    },
    {
      title: "4. Desarrollo de Software Empresarial",
      description: "Soluciones para optimizar procesos internos de empresas, como ERP, CRM, sistemas de recursos humanos.",
      listTitle: "Beneficios",
      items: [
        "Mejora de la eficiencia",
        "Integración de procesos",
        "Gestión de datos centralizada"
      ]
    },
    {
      title: "5. Consultoría en Tecnología de la Información",
      description: "Asesoramiento estratégico para la implementación de tecnologías, optimización de procesos y selección de herramientas.",
      listTitle: "Áreas de enfoque",
      items: [
        "Transformación digital",
        "Arquitectura de sistemas",
        "Análisis de necesidades"
      ]
    },
    {
      title: "6. Integración de Sistemas",
      description: "Conexión de diferentes sistemas y aplicaciones para que funcionen de manera conjunta y eficiente.",
      listTitle: "Ejemplos",
      items: [
        "Integración de CRM con sistemas de marketing",
        "Conexión de aplicaciones internas con servicios en la nube"
      ]
    },
    {
      title: "10. Diseño de UI/UX",
      description: "Creación de interfaces de usuario intuitivas y atractivas, así como experiencias de usuario optimizadas.",
      listTitle: "Elementos clave",
      items: [
        "Investigación de usuarios",
        "Prototipado",
        "Diseño visual",
        "Usabilidad"
      ]
    },
    {
      title: "11. Desarrollo de Software en la Nube",
      description: "Creación de aplicaciones que funcionan en plataformas de nube como AWS, Azure, Google Cloud.",
      listTitle: "Ventajas",
      items: [
        "Escalabilidad",
        "Flexibilidad",
        "Reducción de costos de infraestructura"
      ]
    },
    {
      title: "17. Desarrollo de Chatbots con Inteligencia Artificial",
      description: "Creación de asistentes virtuales inteligentes que interactúan con los usuarios mediante procesamiento de lenguaje natural.",
      listTitle: "Beneficios",
      items: [
        "Mejora de la atención al cliente",
        "Automatización de respuestas",
        "Disponibilidad 24/7"
      ]
    },
    {
      title: "17. Desarrollo de Chatbots con Inteligencia Artificial",
      description: "",
      listTitle: "Tecnologías comunes",
      items: [
        "NLP (Procesamiento de Lenguaje Natural)",
        "Machine learning",
        "Plataformas como Dialogflow, IBM Watson, Microsoft Bot Framework"
      ]
    },
    {
      title: "18. Chatbots para WhatsApp",
      description: "Desarrollo de bots específicos para la plataforma de mensajería WhatsApp, permitiendo la interacción automatizada con los clientes.",
      listTitle: "Características",
      items: [
        "Respuestas automáticas",
        "Integración con CRM",
        "Gestión de consultas frecuentes",
        "Envío de notificaciones y actualizaciones"
      ]
    },
    {
      title: "18. Chatbots para WhatsApp",
      description: "",
      listTitle: "Herramientas y APIs",
      items: [
        "WhatsApp Business API",
        "Twilio",
        "Chat API"
      ]
    },
    {
      title: "19. Servicios de Migración",
      description: "Transferencia de datos y aplicaciones de sistemas antiguos a nuevas plataformas o tecnologías.",
      listTitle: "Beneficios",
      items: [
        "Modernización de infraestructuras",
        "Mejora de rendimiento",
        "Reducción de costos operativos"
      ]
    },
    {
      title: "20. Desarrollo de APIs y Servicios Web",
      description: "Creación de interfaces de programación de aplicaciones (APIs) para facilitar la comunicación entre diferentes sistemas y aplicaciones.",
      listTitle: "Tipos de APIs",
      items: [
        "RESTful",
        "GraphQL",
        "SOAP"
      ]
    },
    {
      title: "22. Desarrollo de E-commerce",
      description: "Creación de plataformas de comercio electrónico personalizadas.",
      listTitle: "Características",
      items: [
        "Integración de pasarelas de pago",
        "Gestión de inventarios",
        "Carritos de compra",
        "Sistemas de gestión de pedidos"
      ]
    }
  ];

  return (
    <main className="main">
      {sections.map((section, index) => (
        <Section
          key={index}
          title={section.title}
          description={section.description}
          listTitle={section.listTitle}
          items={section.items}
        />
      ))}
    </main>
  );
};

export default Main;
