// src/components/Header.js
import React from 'react';
import './Header.css';

const Header = () => (
  <header className="header">
    <h1 className="header-title">searchdominio.online</h1>
    <p className="header-subtitle">Servicios de Desarrollo de Software</p>
  </header>
);

export default Header;
