// src/components/Section.js
import React from 'react';
import './Section.css';

const Section = ({ title, description, listTitle, items }) => (
  <section className="section">
    <h2 className="section-title">{title}</h2>
    <p className="section-description"><strong>Descripción:</strong> {description}</p>
    {listTitle && <p className="section-description"><strong>{listTitle}:</strong></p>}
    {items && (
      <ul className="section-list">
        {items.map((item, index) => (
          <li key={index} className="section-list-item">{item}</li>
        ))}
      </ul>
    )}
  </section>
);

export default Section;
